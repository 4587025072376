.site-footer {

    background: $white;
    position: relative;
    z-index: 0;

    > .container {
        border-top: 2px solid $beige;
        max-width: 1200px;
        padding-top: 30px;
    }

    .footer-col1 {
        padding-left: 0px;

        img {
            position: relative;
            left: -6px;
        }

        address {
            margin-top: 10px;
        }

        ul li {
            display: inline-block;

            a {
                font-size: 12px;
            }

        }

        ul li + li {
            margin-left: 20px;
        }

    }

    .footer-col3 {
        padding-right: 0px;
        text-align: right;
    }

    * {
        color: $main-color;
        font-family: $main-font3;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
    }

    a:hover,
    a:focus {
        color: $brown;
        text-decoration: underline;
        text-underline-position: under;
    }

    strong {
        font-family: $main-font7;
        font-weight: 700;
    }

    address {
        margin-top: 0;
        margin-bottom: 0;
    }

    .footer-logo-mobile-div {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }

    .footer-col1 img {
        width: 167px;
        object-fit: contain;
    }

    .footer-col3 ul:last-child {
        margin-top: 30px;
    }

    .footer-col3 ul:last-child * {
        font-size: 12px;
    }


    .footer-col3 ul:last-child li:last-child a {
        margin-left: 20px;
    }


    .footer-col4 * {
        opacity: 0.5;
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
    }

    .social-links {
        margin-top: 20px;
    }

    .social-links ul {
        margin-left: -10px;
    }

    .social-links ul li {
        display: inline-block;
        padding-left: 10px;
    }

    .social-links ul li a {
        display: inline-block;
        border-radius: 28px;
        background: rgba(255, 255, 255, 0.12);
        width: 28px;
        height: 28px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        transition: all 0.25s ease;
        -webkit-transition: all 0.25s ease;
    }

    .social-links ul li a:hover,
    .social-links ul li a:focus {
        background: rgba(255, 255, 255, 0.22);
    }

    .social-links ul li a img {
        width: 14px;
        object-fit: contain;
    }

    padding: {
        top: 0px;
        bottom: 70px;
    }


    &::before {
       /* content: '';
        display: inline-block;
        background: url('/images/footer-bg.svg') no-repeat 100% 100%;
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-size: contain;*/
    }

    > div {
        position: relative;
        z-index: 2;
    }

    @media (max-width: 1220px) {

        .footer-col1 {
            padding-left: 15px;
        }

        .footer-col3 {
            padding-right: 15px;
        }

    }

    @media (max-width: 959px) {

        padding: {
            /*top: 30px;*/
            top: 0px;
            /*bottom: 45px;*/
            bottom: 0px;
        }

        .footer-col3 .footer-logo-mobile {
            display: none;
        }
    }

    @media (min-width: 768px) {
        .footer-col1 > div {
            text-align: left;
        }
    }


    @media (min-width: 960px) and (max-width: 1200px) {
        .footer-col1 {
            flex: 0 0 50%;
            max-width: 50%;
        }
        .footer-col2 {
            flex: 0 0 25%;
            max-width: 25%;
        }
        .footer-col3 {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }

    @media (min-width: 768px) and (max-width: 959px) {
        .footer-col1 {
            flex: 0 0 40%;
            max-width: 40%;
        }
        .footer-col2 {
            flex: 0 0 30%;
            max-width: 30%;
        }
        .footer-col3 {
            flex: 0 0 30%;
            max-width: 30%;
        }
    }

    @media (max-width: 767px) {

        .footer-col1 {
            margin-bottom: 0;
        }

        .footer-col1 img {
            position: relative;
            left: 0px;
        }

    }
}
