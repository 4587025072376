

.contact-header > div {
    background: url('/images/contact-header-bg.jpg') no-repeat 50% 20% !important;
    padding-top: 65px;
    padding-bottom: 45px;
}

.contact-main .leadix-form input,
.contact-main .leadix-form textarea {
    font-family: $main-font3;
    color: $main-color;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}

.contact-main .leadix-form .form-group {
    margin-bottom: 20px;
}

.contact-main #form-group-required-privacy-notice input {
    display: none;
}

.contact-main .privacy-notice-paragraph {
    padding-left: 28px;
    position: relative;
    z-index: 0;
}

.contact-main .privacy-notice-paragraph::before {
    position: absolute;
    top: 0;
    left: 0;
}

.contact-main .privacy-notice-paragraph a {
    color: $main-color;
}

.contact-main .privacy-notice-paragraph a:hover,
.contact-main .privacy-notice-paragraph a:focus {
    color: $brown;
    text-decoration: underline;
    text-underline-position: under;
}

.contact-main .submit {
    color: #fff;
    border-radius: 0px;
    background-color: $green;
    border: 1px solid $green;
    padding: 11px 28px;
    font-family: $main-font4;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    margin-top: 10px;
}

.contact-main .submit:hover,
.contact-main .submit:focus {
    background-color: $brown;
    border: 1px solid $brown;
}


.contact-main-col2 ul {
    list-style: none;
    padding-left: 0px;
}

.contact-main-col2 ul li a {
    color: $blue-light;
}

.contact-main-col2 ul li a:hover,
.contact-main-col2 ul li a:focus {
    color: $blue-dark;
    text-decoration: underline;
    text-underline-position: under;
}

.contact-map {
    margin-top: 40px;
}





@media (min-width: 960px) {
    .contact-main {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .contact-main-col2 {
        padding-left: 100px;
    }


    .contact-main .form-label {
        min-width: 35% !important;
    }
    .contact-main .privacy-notice-paragraph, .contact-main .submit {
        margin-left: 35%;
    }

}

@media (min-width: 1200px) {
    .contact-main > .container {
        max-width: 1230px;
    }
    .contact-bottom .product-usps-section > section.container {
        max-width: 1230px;
    }
}


@media (min-width: 1440px) {
    .contact-main .form-label {
        min-width: 30% !important;
    }
    .contact-main .privacy-notice-paragraph, .contact-main .submit {
        margin-left: 30%;
    }
}


@media (max-width: 959px) {

    .contact-main {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .contact-main > div > div > div.col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .contact-main > div > div > div.col-md-6.contact-main-col2 {
        margin-top: 40px;
    }


    .contact-main .form-label {
        min-width: 20% !important;
    }
    .contact-main .privacy-notice-paragraph, .contact-main .submit {
        margin-left: 20%;
    }


}


@media (max-width: 767px) {
    .contact-main-col2 {
        /*padding-top: 40px;*/
        padding-top: 0px;
    }

    .contact-main .form-label {
        min-width: 100% !important;
    }
    .contact-main .privacy-notice-paragraph, .contact-main .submit {
        margin-left: 0%;
    }


}
