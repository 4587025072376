input[type=radio] {
    display: none;
    & + label {
        display: inline-block;
        &:before {
            @extend .far;
            @extend .fa-circle;
            display: inline-block;
            margin-right: 10px;
            font-size: 1.2rem;
            transform: translateY(2px);
        }
    }
    &:checked {
        & + label {
            &:before {
                @extend.fas;
                @extend .fa-check-circle;
                /*color: $main-color;*/
                color: $green;
            }
        }
    }
}

input[type=checkbox] {
    display: none;
    & + label {
        display: inline-block;
        &:before {
            @extend .far;
            @extend .fa-square;
            display: inline-block;
            margin-right: 10px;
            font-size: 1.3rem;
            transform: translateY(2px);
        }
    }
    &:checked {
        & + label {
            &:before {
                @extend.fas;
                @extend .fa-check-square;
                /*color: $main-color;*/
                /*color: $brown-v1;*/
                color: $green;
            }
        }
    }
}

.size-box {
    &:not(:first-child) {
        @extend .px-2;
    }
    &:first-child {
        @extend .pl-3;
        @extend .pr-2;

    }
    input {
        display: none;

        + label {
            padding: 10px 10px;
            min-width: 45px;
            height: 45px;
            text-align: center;
            /*border:1px solid $pastel-3-accent;*/
            border:1px solid $brown-v1;
            cursor: pointer;
            &:before {
                display: none;
            }
        }
        &:checked {
            + label {
               /* border:1px solid $pastel-2-accent;
                background-color: $pastel-2;*/
                border:1px solid $brown-v2;
                background-color: $brown-v2;
                &:before {
                    display: none;
                }
            }
        }
        &:disabled {
            +label {
                border:1px solid $gray-400;
                color: $gray-400;
                position: relative;
                display: block;
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M100 0 L0 100 ' stroke='black' stroke-width='1'/><path d='M0 0 L100 100 ' stroke='black' stroke-width='1'/></svg>");
                    background-repeat:no-repeat;
                    background-position:center center;
                    background-size: 100% 100%, auto;
                    opacity: .6;

                }
            }
        }
    }
}
