
.cart-content-header {
    /*border-bottom: 1px solid #cccccc;*/
}
.cart-content-row {
    & + .cart-content-row {
        /*border-top: 1px solid #cccccc;*/
    }
}

.cart-contents {
    .delete-link {
        color: rgba(0,0,0,0.3);
        text-decoration: none;
        &:hover {
            color: $main-color;
        }
    }
}

.cart-totals {
    .total-amount {
        /*border:  1px solid #cccccc;
        background-color: #f9f9f9;*/
      /*  border: 1px solid rgba(7, 41, 57, 0.10);
        background: rgba(7, 41, 57, 0.06);
        border-radius: 4px;*/
        padding: {
            top: 10px;
            bottom: 10px;
        }
        & + .total-amount {
          /*  border-top: none;*/

        }
    }
}

.proceed-button {
    position: relative;
    z-index: 1;
    a {
        @extend .uk-button;
        @extend .order-btn;
    }
}

section.cart-header > div.container {
    background: url('/images/cart-header-bg.jpg') no-repeat 0% 40% !important;
    padding-top: 88px;
    padding-bottom: 68px;
}

section.cart-header > div.container::before {
    background: rgba(117, 113, 81, 0.75);
}



section.checkout-header > div.container {
     background: url('/images/checkout-header-bg.jpg') no-repeat 0% 50% !important;
     padding-top: 88px;
     padding-bottom: 68px;
 }

section.checkout-header > div.container::before {
    background: rgba(215, 199, 182, 0.50);
}

section.cart-header {
  /*  padding: {
        top: 60px;
        bottom: 60px;
    }
    position: relative;
    background: {
        image: url("/images/home-intro-bg.jpg");
        size: cover;
        position: center;
    }
    &:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $dark-bg-color;
        opacity: .8;
    }*/
    h1 {
/*        z-index: 2;
        position: relative;
        margin-top: 110px;
        display: inline-block;
        font-size: 48px;
        color: $light-font-color;*/
      /*  &:after {
            content: "shop";
            z-index: -1;
            position: absolute;
            bottom: 0;
            right: 50px;
            color: $main-color;
            font: {
                family: $hand-font;
                size: 100px;
                weight: normal
            }
        }*/
    }
}

body.cart-page, body.checkout-page {
    position: relative;
    @include media-breakpoint-up(lg) {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 50%;
            height: 100%;
            background: {
                image: url("/images/bg-trending-left.png");
                repeat: repeat-y;
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            width: 50%;
            height: 100%;
            transform: scaleX(-1);
            background: {
                image: url("/images/bg-trending-left.png");
                repeat: repeat-y;
                position: left;
            }
        }
    }

}

.checkout-page {
.cart-totals .total-amount:last-of-type {
    position: relative;
}

}

.form-steps {
    list-style-type: none;
    padding-left: 0px;
    margin-left: -20px;
    li {
        display: inline-block;
        padding-left: 20px;
        margin-right: 5px;
        a, span {
            display: inline-block;
            padding: 10px 10px;
            color: $blue-dark;
        }
        &.enabled, &.current {
                color: $blue-light;
            a, span {
                color: $blue-light;
            }
        }

    }
}




.cart-content-section, .checkout-page-form {
    padding-top: 40px;
    padding-bottom: 15px;
}

.cart-content-section {
    padding-top: 85px;
    padding-bottom: 100px;
}

.cart-content-section .cart-totals, .checkout-page-form .cart-totals {
    margin-right: 15px;
}

.cart-content-section .cart-totals, .account-order .cart-totals {
    margin-right: 0px;
    padding: 30px !important;
}

.cart-content-section .cart-totals > .cart-content-header > div, .checkout-page-form .cart-totals .cart-content-header > div {
    padding-left: 0;
    padding-right: 0;
}

.cart-content-section .cart-totals .cart-content-main,
.cart-content-section .cart-totals .cart-content-proceed,
.cart-content-section .cart-totals .cart-content-proceed-shopping {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.cart-content-section .cart-totals .cart-content-main > div,
.cart-content-section .cart-totals .cart-content-proceed > div,
.cart-content-section .cart-totals .cart-content-proceed-shopping > div {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.cart-content-section .cart-totals .cart-content-main > div > div {
    margin-left: 0px;
    margin-right: 0px;
}

.cart-content-section .cart-totals .cart-content-main > div > div > div {
    padding-left: 0px;
    padding-right: 0px;
}

.cart-content-section .cart-contents > div {
    margin-left: 0px;
    margin-right: 0px;
}

.cart-content-section .cart-contents > div > div.cart-content-row {
    padding-left: 0px;
    padding-right: 0px;
}

.cart-content-section .cart-content-row > .cart-content-row, .checkout-page-form-block {
/*    border: 1px solid rgba(7, 41, 57, 0.10);
    background: rgba(7, 41, 57, 0.06);
    padding: 15px;
    border-radius: 4px;*/
}

.cart-content-section .cart-contents > .row > .cart-content-row .prod-img img {
    width: 82px !important;
    height: 82px !important;
    object-fit: cover !important;
    max-width: none !important;
}


.cart-content-section .your-cart-title {
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(29, 29, 27, 0.10);
    font-family: $main-font7;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
    color: $main-color;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    text-transform: none;
    letter-spacing: -0.5px;

    .cartitems {
        color: rgba(29, 29, 27, 0.50);
        text-align: right;
        font-family: $main-font3;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

}

/*.cart-content-section .cart-contents > .row > .cart-content-row > .cart-content-row {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(29, 29, 27, 0.10);
}

.cart-content-section .cart-contents > .row > .cart-content-row > .cart-content-row:last-child {
    border-bottom: 0px;
}*/

.cart-content-section .cart-contents > .row > .cart-content-row > .cart-content-row {
    padding-top: 20px;
    border-top: 1px solid rgba(29, 29, 27, 0.10);
}

.cart-content-section .cart-contents > .row > .cart-content-row:first-child > .cart-content-row {
    padding-top: 0px;
    border-top: 0px;
}

.cart-content-section .cart-contents > .row > .cart-content-row + .cart-content-row {
    margin-top: 20px;
}

.cart-content-section .cart-contents > .row > .cart-content-row strong {
    font-family: $main-font5 !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 24px;
    color: $main-color;
}




/*



.cart-contents .cart-qty-selector input[type="number"] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: textfield;
}


.cart-contents .cart-qty-selector input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
!*    width: 8px;
    height: 6px;
    background-color: rgba(29, 29, 27, 0.50);
    border: none;
    text-align: center;
    cursor: pointer;*!

!*    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);*!

!*    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 9px solid #737373;*!

    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 9px solid #737373;

}


.cart-contents .cart-qty-selector input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
}

*/

.cart-contents .cart-qty-selector {
    position: relative;
    z-index: 0;
}

.cart-contents .cart-qty-selector input {
    text-align: left;
    height: 32px !important;
    width: 65px;
    /*position: relative;
    z-index: 0;*/
}

.cart-contents .cart-qty-selector .cart-qty-select-open {
    position: absolute;
    top: 50%;
    right: 10%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: auto;
    width: auto;
    background-color: transparent;
    border: 0px;
    text-align: left;
    cursor: pointer;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid rgba(29, 29, 27, 0.50);
    width: 0px;
    height: 0px;
    padding: 0px;
    z-index: 1;
}

.cart-contents .cart-qty-selector input[type="number"] {
    border-radius: 6px;
    border: 1px solid rgba(29, 29, 27, 0.10);
    background: #FFF;
}

.cart-contents .prod-subtotal {
    padding-right: 0px;
    padding-top: 6px;
    text-align: right;
}

.cart-contents .prod-subtotal * {
    font-family: $main-font5;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.cart-contents .prod-remove img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.cart-totals .cart-content-main {
    /*border: 1px solid rgba(7, 41, 57, 0.10);
    background: rgba(7, 41, 57, 0.06);
    border-radius: 4px;*/
}

.cart-totals .cart-content-main > div > div + div {
    border-top: 1px solid rgba(7, 41, 57, 0.10);
}

.cart-totals .proceed-button a {
    color: #FFF;
    border-radius: 0px;
    background-color: $green;
    border-color: $green;
    padding: 11px 28px;
    font-family: $main-font4;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
/*    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;*/
    text-transform: uppercase;

    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

     img {
         height: 10px;
         object-fit: contain;
         margin-left: 15px;
         position: relative;
         top: -2px;
         left: 0px;
         transition: all 0.25s ease;
         -webkit-transition: all 0.25s ease;
     }

    &:after {
        content: "";
        background: #8D533D;
        position: absolute;
        z-index: -1;
        padding: 0.85em 0.75em;
        display: block;
        transition: all 0.3s ease;
        left: -20%;
        right: -20%;
        top: 0;
        bottom: 0;
        transform: skewX(45deg) scale(0, 1);
    }

   /* &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border-radius: 60px;
        z-index: -2;
    }
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken($blue-light, 15%);
        transition: all .3s;
        border-radius: 60px;
        z-index: -1;
    }*/
    &:hover {
        color: #FFF;
/*        background-color: $brown;
        border-color: $brown;*/

        img {
            left: 15px;
        }

      /*  &:before {
            width: 100%;
        }*/
        &:after {
            transition: all 0.3s ease-out;
            transform: skewX(45deg) scale(1, 1);
        }
    }

}

.cart-totals .proceed-button a:hover,
.cart-totals .proceed-button a:focus {
    //background-color: $blue-light;
    border-color: $blue-light;
}

.cart-totals .proceed-shopping-button a {
    color: $green;
    border-radius: 0px;
    background-color: transparent;
    border-color: $green;
    border: 1px solid $green;
    padding: 11px 28px;
    font-family: $main-font4;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    /*    transition: all 0.25s ease;
        -webkit-transition: all 0.25s ease;*/
    text-transform: uppercase;

    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    text-decoration: none;

    img {
        height: 10px;
        object-fit: contain;
        margin-left: 15px;
        position: relative;
        top: -2px;
        left: 0px;
        transition: all 0.25s ease;
        -webkit-transition: all 0.25s ease;
    }

    &:after {
        content: "";
        background: rgba(117,113,81,0.2);
        position: absolute;
        z-index: -1;
        padding: 0.85em 0.75em;
        display: block;
        transition: all 0.3s ease;
        left: -20%;
        right: -20%;
        top: 0;
        bottom: 0;
        transform: skewX(45deg) scale(0, 1);
    }

    /* &:after {
         content: '';
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 100%;
         background-color: transparent;
         border-radius: 60px;
         z-index: -2;
     }
     &:before {
         content: '';
         position: absolute;
         bottom: 0;
         left: 0;
         width: 0%;
         height: 100%;
         background-color: darken($blue-light, 15%);
         transition: all .3s;
         border-radius: 60px;
         z-index: -1;
     }*/
    &:hover {
        color: $green;
        text-decoration: none;
/*        border-color: $brown;
        border: 1px solid $brown;*/

        img {
            left: 15px;
        }

        /*  &:before {
              width: 100%;
          }*/

        &:after {
            transition: all 0.3s ease-out;
            transform: skewX(45deg) scale(1, 1);
        }

    }

}


.cart-nav-top {
    padding-top: 70px;
}

.form-steps li a:hover,
.form-steps li a:focus {
    text-decoration: underline;
    text-underline-position: under;
}

.remarks-row {
    margin-top: 0px !important;
}


.checkout-page-form-block + .checkout-page-form-block {
    margin-top: 20px;
}

.checkout-page-form-block > div.my-4 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.checkout-page-form-block .password-fields.show {
    margin-top: 30px !important;
}

/*.checkout-page-form-block .form-group:last-child, .checkout-page-form-block .form-group:last-child label {
    margin-bottom: 0px !important;
}*/

.checkout-page-form .create_account, .checkout-page-form .create_account label,
/*.checkout-page-form .shipping-type-shipping .form-group, .checkout-page-form .shipping-type-shipping .form-group label,*/
.remarks-row .form-group, .remarks-row .form-group label,
.checkout-page-form .payment-form, .checkout-page-form .payment-form label {
    margin-bottom: 0px !important;
}



.transport-cost-notification {
    display: none;
}


.cart-content-section .cart-totals, .account-order .cart-totals {
    background: rgba(29, 29, 27, 0.04);
}


.cart-content-section .cart-totals * {
    color: $main-color;
    font-family: $main-font3;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.cart-content-section .cart-totals strong, .account-order .cart-totals strong {
    font-family: $main-font2 !important;
    font-weight: 600 !important;
}

.cart-content-section .cart-totals small, .account-order .cart-totals small {
    font-size: 10px;
}


.cart-content-proceed, .cart-content-proceed-shopping {
    text-align: center;
}

.cart-item-block .prod-info {
    padding-left: 30px;
}


.sp-checkout-section h4.checkout-header {
    color: $main-color;
    font-family: $main-font5;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}


.sp-checkout-section .form-control, .sp-checkout-section .select2-container .selection .select2-selection {
    border-radius: 0px;
}

.checkout-page-form-block-payment label {
    width: 100%;
}

.checkout-page-form-block .btn {
    height: 45px;
    text-transform: uppercase;
}

.checkout-page-form-block .btn img {

    height: 10px;
    object-fit: contain;
    margin-left: 15px;
    position: relative;
    top: -2px;
    left: 0px;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}


.checkout-page-form-block-payment .payment-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.checkout-page-form-block-payment .payment-form {
    padding-left: 10px;
    padding-right: 10px;
}

.checkout-page-form-block-payment .payment-form > div {
    flex: 1 0 50%;
    max-width: 50%;
    margin-left: -10px;
    margin-right: -10px;
}

.checkout-page-form-block-payment .payment-form > div > div {
    border: 1px solid rgba(29, 29, 27, 0.20);
/*    position: relative;
    z-index: 0;*/
    margin-bottom: 20px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.checkout-page-form-block-payment label {
    padding: 30px;
}


/*.checkout-page-form-block-payment .payment-form > div > div label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}*/


@media (min-width: 992px) {

    .cart-page-cart-content-totals {
        margin-left: 3.333333%;
        flex: 0 0 30%;
        max-width: 30%;
    }

    .cart-totals-html-mobile {
        display: none;
    }
}

@media (min-width: 1200px) {
    .cart-nav-top,
    .cart-content-section,
    .checkout-page-form {
        max-width: 1230px;
    }

}


@media (min-width: 1440px) {
    .cart-item-block .prod-img,
    .cart-item-block .prod-qty,
    .cart-item-block .prod-subtotal,
    .cart-item-block .prod-remove {
        flex: 0 0 12.50%;
        max-width: 12.50%;
    }
}


@media (max-width: 991px) {

    .category-header > .container {
        background: url('/images/categorie-bg-top.jpg') no-repeat 33% 50%;
    }

    .product-header > .container {
        background: url('/images/product-header-bg.jpg') no-repeat 25% 50% !important;
    }

    section.cart-header > div.container {
        background: url('/images/cart-header-bg.jpg') no-repeat 50% 40% !important;
    }

    .contact-header > div {
        background: url('/images/contact-header-bg.jpg') no-repeat 70% 20% !important;
    }

    .over-ons-header > .container {
        background: url('/images/about-us-header-bg.jpg') no-repeat 40% 40% !important;
    }

    .cart-totals-html-desktop {
        display: none;
    }
    .cart-totals-html-mobile {
        border: 0px !important;
        background: transparent !important;
        padding: 0px !important;
    }

    .cart-totals-html-mobile .cart-totals {
        padding-left: 15px !important;
    }

    .cart-page-cart-content .cart-page-cart-content-totals {
        margin-top: 40px;
    }

    .cart-page-cart-content .cart-page-cart-content-totals > .cart-totals {
        padding-left: 15px !important;
    }
}




@media (min-width: 768px) {
    .cart-content-row .prod-img {
        min-width: 112px;
    }

    .cart-content-row .prod-info {
        flex: 0 0 calc(50% - 50px);
        max-width: calc(50% - 50px);
    }
}


@media (min-width: 992px) and (max-width: 1200px) {
    .cart-totals-html-desktop {
        margin-left: 0;
        flex: 0 0 33.333%;
        max-width: 33.333%;
    }

    .cart-content-row .prod-info {
        flex: 0 0 calc(50% - 60px);
        max-width: calc(50% - 60px);
    }

}

@media (min-width: 960px) and (min-height: 1048px) {
    .confirmation-section {
        min-height: calc(100svh - 848px);
    }
}

@media (max-width: 959px) {
    .sp-checkout-section .cart-nav-top, .cart-section .cart-nav-top {
        padding-top: 40px;
    }
    .sp-checkout-section .checkout-page-form, .cart-section .cart-content-section {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}


@media (max-width: 767px) {
    .cart-content-section .cart-content-row .col-md-8 {
        padding-top: 20px;
    }

    .cart-item-block .prod-info {
        padding-left: 15px;
        padding-top: 15px;
    }

    .cart-contents .prod-subtotal {
        padding-right: 15px;
        padding-top: 6px;
        padding-bottom: 10px;
        text-align: right;
    }

}

@media (max-width: 680px) {
    .form-steps li {
        display: block;
    }
    .form-steps li + svg, .form-steps li + i {
        display: none;
    }
}


@media (max-width: 564px) {
    .checkout-page-form-block .btn {
        width: 100%;
    }
    .checkout-page-form-block .butn-next {
        margin-bottom: 20px;
    }
}
