.main-categories-filter {
    .main-category-item {
        text-decoration: none !important;
        position: relative;
            transition: all 300ms ease;
        h2 {
            position: relative;
            z-index: 2;
            max-width: 140px;
            color: $light-font-color;
            font-size: 30px;
            font-weight: 700;
            transition: all 300ms ease;
        }
      /*  &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $dark-bg-color;
            opacity: .8;
        }
        &:after {
            position: absolute;
            z-index:3;
            content: "";
            bottom:0;
            left: 0;
            height: 3px;
            width: 0;
            transition: all 300ms ease;
            display: block;
            background-color: $main-color;
        }*/
        &:hover, &.active {
            &:after {
                width: 100%;
            }
            h2 {
                color: $main-color;
            }
        }
    }
}

.category-filter {

    .category-filter-item-lvl-1, .category-filter-item  {
        color: $font-color;
        transition: all 300ms ease;
        text-decoration: none !important;
        h3 {
            font-size: 22px;
            font-weight: 700;
            color: $font-color;
            transition: all 300ms ease;
        }
        &:hover, &.active {
            color: $brown;
            h3 {
                color: $main-color;
            }
        }
    }
    .category-filter-item {

        .subcategories-list {
            margin: 0;
            list-style: none;

            li.sub-cat {
                padding-left: 0px;

                a {
                    padding-left: 0px;
                }
            }
        }
    }

}

.category-header {
    position: relative;
    z-index: 0;
/*    background: {
        color: $pastel-1;
    }*/

  /*  &:before {
        position: absolute;
        z-index: 0;
        content: '';
        background: url('/images/categorie-bg-top-after.png') no-repeat 50% 50%;
        right: 0;
        bottom: 0;
        width: 301px;
        height: 322px;
        display: inline-block;
    }*/

    > .container {
        background: url('/images/categorie-bg-top.jpg') no-repeat 0% 50%;
        padding-top: 65px;
        padding-bottom: 45px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
        max-width: 1200px;
        padding-right: 70px;
        position: relative;
        z-index: 0;
        min-height: 230px;
    }

    > .container::before {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: "";
        background: rgba(117, 113, 81, 0.75);
    }

    > .container::after {
        position: absolute;
        z-index: 0;
        content: '';
        background: url('/images/slider-after.svg') no-repeat 50% 50%;
        background-size: contain;
        /*right: -155px;*/
        right: -205px;
        bottom: -160px;
        width: 301px;
        height: 322px;
        display: inline-block;
    }

    > .container > div {
        position: relative;
        z-index: 2;
    }

    h1 {
        position: relative;
        z-index: 2;
        display: inline-block;
        font-size: 40px;
        font-weight: 400;
        font-family: $main-font7;
        position: relative;
        color: $white;
        max-width: 444px;
        margin-left: auto;
        text-align: right;
        text-transform: uppercase;
        line-height: normal;
    }
}


.login-header > div {
    background: url('/images/login-header-bg.jpg') no-repeat 50% 45% !important;
    padding-top: 65px;
    padding-bottom: 45px;
}

.search-header > div {
    background: url('/images/search-header-bg.jpg') no-repeat 50% 20% !important;
    padding-top: 65px;
    padding-bottom: 45px;
}


.search-detail-header > div {
    background: url('/images/search-detail-header-bg.jpg') no-repeat 50% 20% !important;
    padding-top: 65px;
    padding-bottom: 45px;
}


.category-detail {
    position: relative;
    padding-top: 70px;
    /*overflow-x: hidden;*/

    .category-detail-main {

        #overview-products .product-square .product-info {
            padding: 10px 0 6px 0 !important;

            .product-price {
                /*font-family: $main-font;*/
                font-family: 'Calibri', sans-serif;

                .old-price {

                }

                .inclbtwlabel {
                    font-size: 10px;
                }

            }

            .product-title,
            .productpricespan {
                /*font-size: 18px !important;
                font-weight: 300;*/
            }
        }

        .category-filters-container {

            h4 {
                /*font-family: $main-font;*/
                font-family: $main-font2;
                color: $brown;
                font-weight: 400;
                margin-bottom: 0 !important;
                padding-bottom: 0;

                > div {

                    > div:first-child {
                        font-family: $main-font2;
                        color: $brown;
                        font-weight: 400;
                    }

                    > div:last-child {
                        display: none;
                    }
                }
            }

            .category-filter {

                .category-filter-item > div {
                    flex-wrap: inherit;
                    gap: 10px;
                }

                .category-filter-item[aria-expanded="true"] > div > svg {
                    transform: rotate(180deg);
                    transition: 0.25s ease-in-out;
                }

                .category-filter-item[aria-expanded="false"] > div > svg {
                    transform: rotate(0deg);
                    transition: 0.25s ease-in-out;
                }

                a.main-cat {
                    font-family: $main-font3;
                    font-size: 20px;
                    line-height: 42px;
                }

                .sub-cat > a  {
                    padding-left: 0;
                    padding-right: 0;

                    > div {
                        /*font-family: $main-font;*/
                        font-family: 'Calibri', sans-serif;
                        line-height: 18px;
                        font-size: 16px;
                    }
                }

                .filter-form label {
                    font-family: $main-font;
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: 300;
                }
            }

            .category-filter:last-child {

                > div:nth-last-child(1),
                > div:nth-last-child(2) {
                    display: none;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 50%;
            height: calc(100%);
            transform: translateX(-25%);
            opacity: .6;
            background: {
                image: url("/images/bg-trending-left.png");
                repeat: repeat-y;
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            width: 50%;
            height: calc(100%);
            transform: translateX(20%);
            opacity: .6;
            background: {
                image: url("/images/bg-trending-right.png");
                repeat: repeat-y;
                position: right;
            }
            display: none !important;
        }
    }
}

.home-contact-section .home-contact {

    .title-top {
        font-family: $main-font;
    }

    .title-bottom {
        font-family: $main-font;
    }
}



.category-usps > div.container,
.product-usps-section > div.container {
    max-width: 1230px;
}


.category-usps ul, .product-usps-section ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding-left: 0px;
    margin-top: 45px;
    margin-bottom: 55px;
}


.category-usps ul li, .product-usps-section ul li {
    border-right: 1px solid rgba(7, 41, 57, 0.10);
    text-align: center;
}

.category-usps ul li:nth-child(1), .product-usps-section ul li:nth-child(1) {
    text-align: left;
}

.category-usps ul li:nth-child(4), .product-usps-section ul li:nth-child(4) {
    text-align: right;
}

.category-usps ul li:nth-child(1), .product-usps-section ul li:nth-child(1),
.category-usps ul li:nth-child(4), .product-usps-section ul li:nth-child(4) {
    flex: 1 0 21%;
    max-width: 21%;
}

.category-usps ul li:nth-child(2), .product-usps-section ul li:nth-child(2),
.category-usps ul li:nth-child(3), .product-usps-section ul li:nth-child(3) {
    flex: 1 0 29%;
    max-width: 29%;
}

.category-usps ul li:last-child, .product-usps-section ul li:last-child {
    border-right: none;
}

.category-usps ul li span, .product-usps-section ul li span {
    text-align: left;
}



.category-usps ul li span, .product-usps-section ul li span {
    font-family: $main-font7;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    color: $main-color;
}


.category-usps ul li:nth-child(1) span, .product-usps-section ul li:nth-child(1) span {
    position: relative;
    padding-left: 80px;
    display: inline-block;
    width: 145px;
}

.category-usps ul li:nth-child(2) span, .product-usps-section ul li:nth-child(2) span {
    position: relative;
    padding-left: 60px;
    display: inline-block;
    width: 145px;
}

.category-usps ul li:nth-child(3) span, .product-usps-section ul li:nth-child(3) span {
    position: relative;
    padding-left: 60px;
    display: inline-block;
    width: 170px;
}

.category-usps ul li:nth-child(4) span, .product-usps-section ul li:nth-child(4) span {
    position: relative;
    padding-left: 60px;
    display: inline-block;
    width: 160px;
}

.category-usps ul li:nth-child(1) span::before, .product-usps-section ul li:nth-child(1) span::before {
    content: '';
    background: url('/images/delivery-truck.svg') no-repeat 0% 50%;
    position: absolute;
    top: 50%;
    left: 0%;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    background-size: contain;
    width: 66px;
    height: 64px;
}

.category-usps ul li:nth-child(2) span::before, .product-usps-section ul li:nth-child(2) span::before {
    content: '';
    background: url('/images/advice.svg') no-repeat 0% 50%;
    position: absolute;
    top: 50%;
    left: 0%;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    background-size: contain;
    width: 45px;
    height: 46px;
}

.category-usps ul li:nth-child(3) span::before, .product-usps-section ul li:nth-child(3) span::before {
    content: '';
    background: url('/images/euro.svg') no-repeat 0% 50%;
    position: absolute;
    top: 50%;
    left: 0%;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    background-size: contain;
    width: 43px;
    height: 46px;
}

.category-usps ul li:nth-child(4) span::before, .product-usps-section ul li:nth-child(4) span::before {
    content: '';
    background: url('/images/quality.svg') no-repeat 0% 50%;
    position: absolute;
    top: 50%;
    left: 0%;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    background-size: contain;
    width: 43px;
    height: 46px;
}

.category-detail-main > .category-detail-filter-left > .col-md-4 h4,
.category-detail-main > .category-detail-filter-left > .col-md-4 .filter-title, .pricefiltertitle h4 {
    font-family: $main-font7;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 46px;
    letter-spacing: 0px;
    color: $main-color;
}

.category-detail-main > .category-detail-filter-left > .col-md-4 .category-filter-item a {
    font-family: $main-font3;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    color: $main-color;
    display: inline-block;
}

.category-detail-main > .category-detail-filter-left > .col-md-4 .category-filter-item a.active {
    font-family: $main-font2;
    font-weight: 700;
}

.category-detail-main > .category-detail-filter-left > .col-md-4 .category-filter {
    padding-bottom: 15px;
}

.category-detail-main > .category-detail-filter-left > .col-md-4 .category-filter .specfiltertitle:first-child {
    margin-top: 3px !important;
}

.brandfiltertitle, .specfiltertitle {
    border-top: 1px solid #D9D9D9;
    padding-top: 15px;
}

.pricefiltertitle h4 {
    border-top: 1px solid #D9D9D9;
    padding-top: 7px;
    margin-top: 10px;
}

.price-input input {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    height: 46px;
    font-family: $main-font1;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: $main-color;
}

.pricefilter .price-input {
    position: relative;
}

.pricefilter .price-input .minpricelabel,
.pricefilter .price-input .maxpricelabel {
    position: absolute;
    top: 0;
    left: 20px;
    z-index: 1;
    font-family: $main-font1;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: $main-color;
    text-transform: uppercase;
}


.cat-title .cat-title-name {
    font-family: $main-font7;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0px;
    color: $main-color;
}

.cat-title .result-count {
    display: inline-block;
    font-family: $main-font3;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: rgba(29, 29, 27, 0.50);
    margin-left: 10px;
}

.category-detail-main #overview-products {
    margin-top: 30px;
    margin-left: -7px;
    margin-right: -7px;
}

.category-detail-main #overview-products > div {
    padding-left: 7px !important;
    padding-right: 7px !important;
}

.category-detail-main #overview-products .product-list-item {
    padding-top: 0px;
}

.category-detail-main #overview-products .product-list-item .product-info {
    margin-top: 10px !important;
    margin-left: 0px;
    margin-right: 0px;
}

.category-detail-main #overview-products .product-list-item .product-info .product-title {
    font-family: $main-font3 !important;
    color: $main-color !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    text-align: center !important;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    max-width: 96% !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.category-detail-main #overview-products .product-list-item .product-info .product-price {
    font-family: $main-font3 !important;
    color: $main-color !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    text-align: center !important;

    .old-price {
        margin-right: 10px;
        color: rgba(29, 29, 27, 0.50);
    }

}

.category-detail-main #overview-products .product-list-item .product-info .product-link {
    text-align: center;
    margin-top: 15px;
}

.category-detail-main #overview-products .product-list-item .product-info .product-link span {
    font-family: $main-font6 !important;
    color: $main-color !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: center !important;
    text-decoration: underline;
    text-underline-position: under;
    padding: 5px 10px 7px 10px;
}


.category-detail-main .cat-title {
    border-bottom: 1px solid rgba(29, 29, 27, 0.10);
    padding-bottom: 4px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.category-detail-main #overview-products .product-square .image-container {
    height: 224px;
    padding-bottom: 0px;
    width: 224px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0px;
}

.category-detail-main #overview-products .product-square .product-list-item:hover,
.category-detail-main #overview-products .product-square .product-list-item:focus {
    border-radius: 0px;
    background: #FFF;
    /*box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05), 0px 13px 29px 0px rgba(0, 0, 0, 0.05), 0px 53px 53px 0px rgba(0, 0, 0, 0.04), 0px 120px 72px 0px rgba(0, 0, 0, 0.03), 0px 213px 85px 0px rgba(0, 0, 0, 0.01), 0px 332px 93px 0px rgba(0, 0, 0, 0.00);*/
    box-shadow: none;
}

/*.category-detail-main #overview-products .product-square .product-list-item:hover .new-label,
.category-detail-main #overview-products .product-square .product-list-item:focus .new-label,
.category-detail-main #overview-products .product-square .product-list-item:hover .sale-label,
.category-detail-main #overview-products .product-square .product-list-item:focus .sale-label {
    border-top-left-radius: 0px;
}*/

.category-detail-main #overview-products .product-square .product-list-item:hover .product-link span,
.category-detail-main #overview-products .product-square .product-list-item:focus .product-link span {
    font-family: $main-font6;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: $white !important;
    border-radius: 14px;
    background: #090914;
    text-decoration: none;
}

.ftc {
    margin-bottom: 0;
}



.confirm-header > .container {
    background: url('/images/confirm-header-bg.jpg') no-repeat 50% 50% !important;
}

@include media-breakpoint-up(md){
    .collapse.collapse-on-mobile{
        display: block;
        height: auto !important;
        visibility: visible;
    }
}

@media (min-width: 768px) {
    .dont-collapse-sm {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .category-detail .category-detail-main > div > div:first-child {
        padding-left: 15px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .category-detail > .category-detail-main .category-filters-container {
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .category-detail-main {
        max-width: 1230px;
    }
}


@media (min-width: 1440px) {
    .category-detail-main > .category-detail-filter-left > .col-md-4 {
        flex: 0 0 19.166%;
        max-width: 19.166%;
    }
    .category-detail-main > .category-detail-filter-left > .col-md-8 {
        flex: 0 0 80.834%;
        max-width: 80.834%;
        padding-left: 30px;
    }
}


@media (max-width: 1440px) {
    .category-detail-main #overview-products > div {
        overflow: hidden;
    }
}



@media (max-width: 1320px) {

    .category-header > .container {
        overflow: hidden;
    }

    .category-header > .container::after {
        right: -40px;
    }
}

@media (max-width: 991px) {
    .category-detail-main #overview-products .product-square .image-container {
        width: 100%;
    }
}


@media (max-width: 959px) {

    .category-header > .container::after {
        right: -40px;
    }

    .category-usps ul, .product-usps-section ul {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .category-usps {
        padding-top: 20px;
    }

    .pager {
        padding-top: 20px;
        padding-bottom: 40px;
    }

}

@media (max-width: 880px) {
    .category-usps ul, .product-usps-section ul {
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .category-usps ul li:nth-child(1), .product-usps-section ul li:nth-child(1), .category-usps ul li:nth-child(4), .product-usps-section ul li:nth-child(4),
    .category-usps ul li:nth-child(2), .product-usps-section ul li:nth-child(2), .category-usps ul li:nth-child(3), .product-usps-section ul li:nth-child(3) {
        flex: 1 0 50%;
        max-width: 50%;
    }

    .category-usps ul li, .product-usps-section ul li {
        border-right: 0px !important;
        padding-bottom: 30px;
    }

    .category-usps ul li, .product-usps-section ul li {
        text-align: left !important;
    }

    .category-usps ul li:nth-child(1) span, .product-usps-section ul li:nth-child(1) span,
    .category-usps ul li:nth-child(2) span, .product-usps-section ul li:nth-child(2) span,
    .category-usps ul li:nth-child(3) span, .product-usps-section ul li:nth-child(3) span,
    .category-usps ul li:nth-child(4) span, .product-usps-section ul li:nth-child(4) span {
        text-align: left;
        padding-left: 80px;
        width: 200px;
    }


    .category-usps ul li:nth-child(2) span::before, .product-usps-section ul li:nth-child(2) span::before,
    .category-usps ul li:nth-child(3) span::before, .product-usps-section ul li:nth-child(3) span::before,
    .category-usps ul li:nth-child(4) span::before, .product-usps-section ul li:nth-child(4) span::before {
        left: 20px;
    }


}



@media (max-width: 767px) {

    .category-header h1 {
        font-size: 36px;
    }

    .category-detail {
        padding-top: 40px;
    }

    .category-detail-main #overview-products .product-square .image-container {
        width: 100%;
    }

    .category-header > .container {
        padding-right: 40px;
    }

}

@media (max-width: 480px) {
    .category-usps ul li:nth-child(1), .product-usps-section ul li:nth-child(1), .category-usps ul li:nth-child(4), .product-usps-section ul li:nth-child(4),
    .category-usps ul li:nth-child(2), .product-usps-section ul li:nth-child(2), .category-usps ul li:nth-child(3), .product-usps-section ul li:nth-child(3) {
        flex: 1 0 100%;
        max-width: 100%;
    }


    .category-usps ul li:nth-child(1) span, .product-usps-section ul li:nth-child(1) span,
    .category-usps ul li:nth-child(2) span, .product-usps-section ul li:nth-child(2) span,
    .category-usps ul li:nth-child(3) span, .product-usps-section ul li:nth-child(3) span,
    .category-usps ul li:nth-child(4) span, .product-usps-section ul li:nth-child(4) span {
        width: 100%;
    }

    .filter-mob > div > div {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .category-header > .container {
        padding-right: 15px;
    }

    .category-header h1 {
        font-size: 26px;
    }

}



.filter-mob {
    padding-bottom: 20px;
}
