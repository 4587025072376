section#home-intro {
    background-color: $pastel-1;
    position: relative;
    height: 800px;
    color: $pastel-1-accent;
    .content-div {
        position: relative;
        z-index: 10;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 28.13px;
    }
    .container {
        height: 100%;
        color: $black;
    }
    .filled-image {
        position: absolute;
        height: 800px;
        right: 0px;
        padding-left: 0;
        padding-right: 0;
        top: 0;
        >div {
            text-align: right;
        }
        img {
            height: 800px;
           object-fit: cover;
        }
    }
    &:before {
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 80%;
        bottom: 0;
        left: 0;
        color: $pastel-1-accent;
        content: "";
        background-image: url(/images/w.svg);
        background-size: 100%;
        background-position: bottom left;
        background-repeat: no-repeat;
        opacity: .20;
    }
    .uk-button.uk-button-default:hover {
        background-color: #87a4a8;
        color: $white;
    }
}

section#home-about {
    background-color: $pastel-2;
    position: relative;
    height: 700px;
    color: $pastel-1-accent;
    .content-div {
        position: relative;
        z-index: 10;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 28.13px;
        color:$white;
        h2 {
            font-size: 48px;
            font-weight: 700;
            color: $white;
        }
        .uk-button.uk-button-default:hover {
            background-color: #5c6a75;
            color: $white;
        }

    }
    .container {
        height: 100%;
        color: $black;
    }
    .filled-image {
        position: absolute;
        height: 700px;
        left: 0px;
        padding-left: 0;
        padding-right: 0;
        top: 0;
        >div {
            text-align: left;
        }
        img {
            height: 700px;
            object-fit: cover;
        }
    }
    &:before {
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 90%;
        bottom: 0;
        right: 0;
        color: $pastel-2-accent;
        content: "";
        background-image: url(/images/w2.svg);
        background-size: 100%;
        background-position: bottom right;
        background-repeat: no-repeat;
        opacity: .20;
    }
}

section#new-products {
    @extend .py-5;
    @extend .mb-4;
    .uk-position-small[class*=uk-position-center-left]{

        background-color: $pastel-2;
        color: $white;
        padding: 11px 20px ;
        transform: translateX(-120%) translateY(30px);
        font-size: 25px;

    }
    .uk-position-small[class*=uk-position-center-right] {

        background-color: $pastel-2;
        color: $white;
        padding: 11px 20px ;
        transform: translateX(120%) translateY(30px);
        font-size: 25px;

    }
    .new-content > div:first-of-type {

        text-align: center;
        @extend .mb-5;

    }
    h2 {

        display:inline-block;
        margin: 0 auto;
        text-align: center;
        text-transform: uppercase;
        position: relative;
        //border-bottom: 3px dashed $pastel-2;

        &:after {

            content: "";
            position: absolute;
            bottom: -10px;
            left: -10px;
            width: calc(100% + 10px);
            height: 3px;
            background-image: linear-gradient(to right, transparent 50%, $pastel-2 50%);
            background-size: 22px 100%;

        }

    }

    li {
        padding-top: 40px;

        .new-product-item {
            position: relative;
            display: block;
            &:before {

                content: "";
                position: absolute;
                top: -30px;
                left: calc(50% - 50px);
                width: 50%;
                padding-bottom: 24%;
                background-image: url(/images/tape.svg?052e661…);
                background-size: 100%;
                background-position: bottom center;
                z-index: 999;
                background-repeat: no-repeat;
                transform-origin: center;
                opacity: .8;

            }

        }
        &:nth-child(2n) .new-product-item:before {

            //left: 50px;
            transform: scaleX(-1);

        }
        &:nth-child(3n) .new-product-item:before {

            //left: 50px;
            transform:  rotate(20deg);

        }
        &:nth-child(4n) .new-product-item:before {

            //left: 50px;
            transform: rotate(-20deg) scaleX(-1);

        }

    }

}

section#home-social {
    background-color: $pastel-3;
    background-image: url(/images/plane.svg);
    background-repeat: no-repeat;
    background-size: 350px;
    background-position: 0 -55px ;
    height: 240px;
    @extend .py-4;
    @extend .d-flex;
    @extend .align-items-center;
    h3 {
        font-size: 32px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
    }
    h4 {
        margin-top: -15px;
        font-size: 32px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
    }

    .social-buttons {
        ul {
            @extend .row;
            @extend .px-0;
            list-style-type: none;
            li {
                @extend .col-5;
                @extend .py-3;
                &:nth-child(3) {
                    @extend .offset-lg-1;
                }
                a {
                    @extend .uk-button;
                    @extend .w-100;
                    background-color: $white;
                    box-shadow: 0 0 15px $pastel-3-accent;
                    border-radius: 5px;
                    font-size: 18px;
                    padding-top: 7px;
                    padding-bottom: 7px;
                    transition: all 300ms ease-in-out;
                    &:hover {
                        background-color: $button-dark;
                        color: $white;
                    }
                    i {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

section#home-designs {
    background-color: $pastel-1;
    position: relative;
    height: 800px;
    color: $pastel-1-accent;
    .content-div {
        position: relative;
        z-index: 10;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 28.13px;
        h2 {
            font-size: 48px;
            font-weight: 700;
        }
        .uk-button.uk-button-default:hover {
            background-color: #f7cc49;
            color: $black;
        }
    }
    .container {
        height: 100%;
        color: $black;
    }
    .filled-image {
        position: absolute;
        height: 800px;
        right: 0px;
        padding-left: 0;
        padding-right: 0;
        top: 0;
        >div {
            text-align: right;
        }
        img {
            height: 800px;
            object-fit: cover;
        }
    }
    &:before {
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 80%;
        bottom: 0;
        left: 0;
        color: $pastel-1-accent;
        content: "";
        background-image: url(/images/w.svg);
        background-size: 100%;
        background-position: bottom left;
        background-repeat: no-repeat;
        opacity: .20;
    }
}



.header-home {
    /*position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    background: transparent;*/
    box-shadow: none;
}

.slider-text p {
    color: $white;
    text-align: right;
    font-family: $main-font7;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    max-width: 701px;
    letter-spacing: -1px;
}

.slider-button {
    text-align: right;
}




/*.slider-section .uk-slideshow {
    overflow: hidden;
}*/

.slider-section .uk-slideshow::before {
    content: '';
    background: url('/images/slider-after.svg') no-repeat 50% 50%;
    background-size: contain;
    z-index: 0;
    position: absolute;
    bottom: -161px;
    /*right: -150px;*/
    right: -200px;
    width: 301px;
    height: 322px;
    max-width: 100%;
}

.slider-section ul.uk-slideshow-items > li > div > img {
    max-width: 100%;
}

.slider-section ul.uk-slideshow-items::after {
    content: '';
    background: url('/images/celestev-circle.svg') no-repeat 50% 50%;
    background-size: contain;
    position: absolute;
    z-index: 1;
    top: 38%;
    left: -6%;
    width: 324px;
    height: 324px;
    display: inline-block;
    -webkit-animation: rotating 60s linear infinite;
    -moz-animation: rotating 60s linear infinite;
    -ms-animation: rotating 60s linear infinite;
    -o-animation: rotating 60s linear infinite;
    animation: rotating 60s linear infinite;
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.slider-section ul.uk-slideshow-items  .right-chevron {
    height: 10px;
    object-fit: contain;
    margin-left: 15px;
    position: relative;
    top: -2px;
    left: 0px;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}


.slider-section ul.uk-slideshow-items .btn:hover .right-chevron ,
.slider-section ul.uk-slideshow-items .btn:focus .right-chevron {
    left: 15px;
}

.slider-section ul.uk-slideshow-items .uk-position-center-right {
    padding-right: 55px;

}




.header-home.uk-sticky-fixed {
  /*  position: fixed !important;
    background: $blue-dark;
    animation-duration: 0s !important;
    transition: 0s !important;*/
}

.header-home.uk-sticky-fixed + .uk-sticky-placeholder {
    /*height: 108px !important;*/
   /* height: 0px !important;
    animation-duration: 0s !important;
    transition: 0s !important;
    position: fixed !important;*/
}

.header-home + .uk-sticky-placeholder {
    /*height: 108px !important;*/
   /* height: 0px !important;
    animation-duration: 0s !important;
    transition: 0s !important;
    position: fixed !important;*/
}




.home-1 {
    padding-top: 70px;
}


.productslider hr {
    margin-top: 70px;
    border-top: 1px solid rgba(7, 41, 57, 0.10);
}

.productslider > div.container, #related-products > div.container {
    max-width: 1230px;
    margin-left: auto;
    margin-right: auto;
}


.productslider .uk-slider-container, #related-products .uk-slider-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.productslider .uk-slider-items, .related-product-slider .uk-slider-items {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.productslider .image-container, #related-products .image-container {
    height: 200px;
    position: relative;
    z-index: 0;
}


.productslider .image-container:hover::before,
.productslider .image-container:hover::after,
.productslider .image-container:hover >:first-child::before,
.productslider .image-container:hover >:first-child::after {
    position:absolute;
    width:20px; height: 20px;
    border-color: #FFF;
    border-style:solid;
    content: ' ';
    z-index: 1;
}

.productslider .image-container:before {top: 10px;left: 10px;border-width: 2px 0 0 2px;}
.productslider .image-container:after {top: 10px;right: 10px;border-width: 2px 2px 0 0;}
.productslider .image-container >:first-child:before {bottom:  10px;right: 10px;border-width: 0 2px 2px 0;}
.productslider .image-container >:first-child:after {bottom: 10px;left: 10px;border-width: 0 0 2px 2px;}


#related-products .image-container:hover::before,
#related-products .image-container:hover::after,
#related-products .image-container:hover >:first-child::before,
#related-products .image-container:hover >:first-child::after {
    position:absolute;
    width:20px; height: 20px;
    border-color: #FFF;
    border-style:solid;
    content: ' ';
    z-index: 1;
}

#related-products .image-container::before {top: 10px;left: 10px;border-width: 2px 0 0 2px;}
#related-products .image-container::after {top: 10px;right: 10px;border-width: 2px 2px 0 0;}
#related-products .image-container >:first-child::before {bottom: 10px;right: 10px;border-width: 0 2px 2px 0;}
#related-products .image-container >:first-child::after {bottom: 10px;left: 10px;border-width: 0 0 2px 2px;}


.productslider .image-container img, #related-products .image-container img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 224px;
    object-fit: cover;
}

#related-products .related-products-slider-block .image-container img {
    height: 200px;
}

.productslider .product-name, #related-products .product-name {
    margin-top: 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.productslider .product-name span, #related-products .product-name span {
    display: inline-block;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-family: $main-font3;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    color: $main-color;
}

.productslider .discount-price-span {
    display: inline-block;
    margin-left: 10px;
}

.productslider .product-price, #related-products .product-price {
    margin-top: 10px;
    font-family: $main-font3;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /*color: rgba(29, 29, 27, 0.50);*/
    color: $main-color;
}

#related-products .product-price {
    margin-top: 7px;
}

.productslider .product-price .old-price, #related-products .product-price .old-price {
    color: rgba(29, 29, 27, 0.50);
}


.productslider .product-link, #related-products .product-link {
    margin-top: 15px;
}

.productslider .product-link a, #related-products .product-link a {
    font-family: $main-font6;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: $main-color;
    text-decoration: underline;
    text-underline-position: under;
}

.productslider .product-link a:hover,
.productslider .product-link a:focus,
#related-products .product-link a:hover,
#related-products .product-link a:focus {
    color: $blue-light;
}

.productslider .uk-slidenav svg, #related-products .uk-slidenav svg {
    display: none;
}

.productslider .uk-slidenav svg.home-product-slider-fa-chevron-left, #related-products .uk-slidenav svg.home-product-slider-fa-chevron-left,
.productslider .uk-slidenav svg.home-product-slider-fa-chevron-right, #related-products .uk-slidenav svg.home-product-slider-fa-chevron-right {
    display: var(--fa-display,inline-block);
    width: 14px;
    height: 24px;
    color: $brown;
    background: $white;
    border-radius: 50%;
    padding: 5px 10px;
}

.productslider .uk-slidenav svg.home-product-slider-fa-chevron-left:hover, #related-products .uk-slidenav svg.home-product-slider-fa-chevron-left:hover,
.productslider .uk-slidenav svg.home-product-slider-fa-chevron-right:hover, #related-products .uk-slidenav svg.home-product-slider-fa-chevron-right:hover {
    color: $beige;
    background: $brown;
}

.productslider .uk-slidenav, #related-products .uk-slidenav {
    visibility: visible !important;
    display: block !important;
}

.productslider .uk-slidenav img, #related-products .uk-slidenav img {
    width: 25px;
    height: 25px;
    object-fit: contain;
}


.productslider ul.uk-slider-items {
    margin-left: -20px;
}

.productslider ul.uk-slider-items li {
    padding-left: 20px;
}

.home-producten-all  {
    padding-top: 40px;
    padding-bottom: 50px;
}

.home-2 {
    padding-top: 165px;
}

.usps-home {
    position: relative;
    z-index: 0;
    padding-top: 100px;
    padding-bottom: 60px;
}

.usps-home::before {
    content: '';
    background: url('/images/dark-bg-layer.svg') no-repeat 50% 50%;
    background-size: cover;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.usps-home > div {
    position: relative;
    z-index: 2;
    max-width: 1200px;
}

.usps-home > div.container > div.row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.usps-home .col-md-5 > div {
    position: relative;
}

.usps-home .col-md-5 > div img {
    position: absolute;
    top: calc(50% + 77px);
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


.usps-home h2 {
    max-width: 250px;
}

.usps-home .onze-aanpak-p {
    margin-top: 25px;
}

.usps-home-ul {
    margin-top: 40px;
    margin-left: -45px;
    list-style: none;
}

.usps-home-ul li {
    padding-left: 60px;
    display: inline-block;
    position: relative;
    z-index: 0;
    max-width: 210px;
    font-size: 14px;
    text-transform: uppercase;
}

.usps-home-ul li:nth-child(1)::before {
    content: '';
    display: inline-block;
    background: url('/images/technology-1.svg') no-repeat 50% 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 30px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-size: contain;
}

.usps-home-ul li:nth-child(2) {
    max-width: 190px;
}

.usps-home-ul li:nth-child(2)::before {
    content: '';
    display: inline-block;
    background: url('/images/consultant-services-1.svg') no-repeat 50% 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 30px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-size: contain;
}

.usps-home-ul li:nth-child(3)::before {
    content: '';
    display: inline-block;
    background: url('/images/business-1.svg') no-repeat 50% 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 30px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-size: contain;
}

.counter {
    padding-top: 95px;
    padding-bottom: 85px;
}

.counter h2 {
    max-width: 656px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}

.counter ul {
    list-style: none;
    margin-left: -125px;
    padding-left: 0px;
}

.counter ul li {
    display: inline-block;
    padding-left: 125px;
}

.counter ul li span {
    display: block;
}

.counter .li-top {
    font-family: $main-font9;
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: 62px;
    letter-spacing: -2px;
    margin-bottom: 15px;
}

.counter .li-center {
    font-family: $main-font8;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.counter .li-bottom {
    font-family: $main-font10;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.counter hr {
    border-top: 1px solid #E4E4E7;
    max-width: 809px;
    margin-left: auto;
    margin-top: 40px;
    margin-right: auto;
    margin-bottom: 30px;

}


.passie-voor-sieraden > div {
    position: relative;
    z-index: 0;
}

.passie-voor-sieraden > div::before {
    content: '';
    background: url('/images/passie-voor-sieraden-after.svg') no-repeat 50% 50%;
    background-size: contain;
    position: absolute;
    bottom: 111px;
    /*left: -150px;*/
    left: -200px;
    width: 301px;
    height: 322px;
}

.passie-voor-sieraden .col-md-5 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
}

.passie-voor-sieraden .col-md-5 * {
    max-width: 437px;
}

.passie-voor-sieraden h2 {
    font-family: $main-font7;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: none;
}

.passie-voor-sieraden .btn {
    margin-top: 10px;
}

.passie-voor-sieraden .btn img, .home-block2 .btn img {
    height: 10px;
    object-fit: contain;
    margin-left: 15px;
    position: relative;
    top: -2px;
    left: 0px;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.passie-voor-sieraden .btn:hover img,
.passie-voor-sieraden .btn:focus img,
.home-block2 .btn:hover img,
.home-block2 .btn:focus img {
    left: 15px;
}


.home-block2 .col-md-7 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
}


.home-categories ul.uk-slider-items {
    margin-left: -20px;
}

.home-categories ul.uk-slider-items li {
    padding-left: 20px;
}

.home-categories ul.uk-slider-items li div {
    padding: 0px;
}

.home-categories ul.uk-slider-items li .image-container img {
    height: 228px;
    width: 100%;
    object-fit: cover;
}

.home-categories > div > div {
    margin-left: 0;
    margin-right: 0;
}

.home-categories .uk-slidenav img {
    height: 25px;
    object-fit: contain;
}

.home-categories .uk-slidenav svg {
    display: none !important;
}

.home-categories .uk-slidenav svg.home-product-slider-fa-chevron-left, .home-categories .uk-slidenav svg.home-product-slider-fa-chevron-right {
    display: var(--fa-display,inline-block) !important;
    width: 14px;
    height: 24px;
    color: $brown;
    background: $white;
    border-radius: 50%;
    padding: 5px 10px;
}

.home-categories .uk-slidenav svg.home-product-slider-fa-chevron-left:hover, .home-categories .uk-slidenav svg.home-product-slider-fa-chevron-right:hover {
    color: $beige;
    background: $brown;
}


.home-categories .category-list-item {
    position: relative;
    z-index: 0;
}

.home-categories .category-list-item::before {
    content: '';
    background: linear-gradient(180deg, rgba(29, 29, 27, 0.00) 0%, rgba(215, 199, 182, 0.44) 88.54%, rgba(29, 29, 27, 0.33) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.home-categories .category-list-item:hover::before {
    background: transparent;
}

/*
.home-categories .category-list-item .image-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}*/

.home-categories .category-list-item .product-info {
    position: absolute;
    top: auto;
    left: 35px;
    right: 35px;
    bottom: 0px;
    z-index: 2;
    padding: 0px !important;
}

.home-categories .category-list-item .product-info h4 {
    color: $white;
    font-family: $main-font7;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    position: relative;
    z-index: 0;
}

.home-categories .category-list-item .product-info img {
    height: 21px;
    object-fit: contain;
    position: absolute;
    top: 2px;
    right: 15px;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.home-categories .category-list-item:hover .product-info img,
.home-categories .category-list-item:focus .product-info img {
    right: 0px;
}

.home-block2 .col-md-5 img {

}

.home-block2 .col-md-7 > div {
    padding-left: 45px;
}

.home-block2 .col-md-7 .quote {
    color: $green;
    //font-family: $main-font6;
    font-family: $main-font7;
    font-size: 32px;
    font-style: italic;
    font-weight: 300;
    line-height: 42px;
    position: relative;
    z-index: 0;
}

.home-block2 .col-md-7 .quote::before {
    content: '';
    background: url('/images/accent.svg') no-repeat 0 0;
    display: inline-block;
    width: 66px;
    height: 66px;
    position: absolute;
    z-index: -1;
    top: -30px;
    left: -50px;
}

.home-block2 .col-md-7 .onze-aanpak-p {
    margin-bottom: 0px;
}

.home-block2 .btn {
    margin-top: 10px;
}

.home-usps {
    position: relative;
    z-index: 0;
}

.home-usps::before {
    content: '';
    background: url('/images/usps-after.svg') no-repeat 50% 50%;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1;
    width: 100%;
    height: 300%;
}

.home-usps .image-container img {
    height: 80px;
    object-fit: contain;
}


.home-usps ul {
    list-style: none;
    margin-left: -50px;
    padding-left: 0px;
    text-align: center;
}

.home-usps ul li {
    padding-left: 50px;
    display: inline-block;
}

.home-usps .usp-container {
    position: relative;
    z-index: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    min-width: 244px;
    min-height: 244px;
}

.home-usps .usp-container::before {
    content: '';
    background: $beige;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -1;
    min-width: 244px;
    min-height: 244px;
}

.home-usps .text-container {
    color: $main-color;
    text-align: center;
    font-family: $main-font4;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
    max-width: 125px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

#home-usps {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}


.home-new .old-price {
    margin-right: 10px;
}



.home-new  .product-list-item .image-container {
    padding-bottom: 0px;
    height: 224px;
}

.home-new #overview-new-products {
    margin-left: -10px;
    margin-right: -10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-new #overview-new-products > div {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 16px;
}

.home-new  .product-list-item .product-info {
    text-align: center;
    padding-top: 20px !important;
}

.home-new  .product-list-item .product-info h4 {
    color: $main-color;
    text-align: center;
    font-family: $main-font3;;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.home-new  .product-list-item .product-info .product-price {
    color: $main-color;
    text-align: center;
    font-family: $main-font3;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.home-new .product-list-item .product-info .product-price span {
    color: rgba(29, 29, 27, 0.50);
}

.home-new .product-list-item:hover {
    background-color: transparent;
}

.home-new .all-products {
    text-align: right;
}

.home-new .all-products img {
    height: 10px;
    object-fit: contain;
    margin-left: 15px;
    position: relative;
    top: -2px;
    left: 0px;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.home-new .all-products a:hover img,
.home-new .all-products a:focus img {
    left: 15px;
}

.home-new {
    margin-bottom: 80px;
}



@media (min-width: 960px) {

    .home-new #overview-new-products > div.col {
        flex: 0 0 20%;
        max-width: 20%;
    }

}

@media (min-width: 1440px) {
    .usps-home .col-md-5 > div img {
        width: 572px !important;
        max-width: none;
        object-fit: cover;
    }

    .passie-voor-sieraden .col-md-7 img {
        min-width: 812px;
    }

    .home-block2 .col-md-5 img {
        min-width: 608px;
        margin-left: -120px;
    }


}

@media (max-width: 1439px) {
    .usps-home .col-md-5 > div img {
        position: absolute;
        top: calc(50% + 127px);
    }
}


@media (max-width: 1350px) {
    .productslider > div.container, #related-products > div.container,
    .productslider .uk-slider-container, #related-products .uk-slider-container {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }


    .productslider-home h2 {
        text-align: center;
    }


}




@media (max-width: 1320px) {
    .home-categories .uk-position-center-left-out {
        right: calc(100% - 60px);
    }

    .home-categories .uk-position-center-right-out {
        left: calc(100% - 60px);
    }

    .slider-section .uk-slideshow::before {
        right: -40px;
    }
}









@media (max-width: 1080px) {
    .usps-home .col-md-5 > div img {
        position: absolute;
        top: calc(50% + 127px);
    }

}


@media (max-width: 959px) {

    .slider-section > div {
        padding-left: 0px;
        padding-right: 0px;
    }

    .home-new {
        margin-bottom: 20px;
    }

    .home-categories .category-list-item .product-info h4 {
        font-size: 20px;
    }

    .slider-section .uk-slideshow-items {
        min-height: 300px !important;
    }

    .slider-section ul.uk-slideshow-items .uk-position-center-right {
        padding-right: 0px;
    }

    .slider-text p {
        font-size: 26px;
    }

    .passie-voor-sieraden h2 {
        font-size: 26px;
    }

    #home-usps {
        padding-left: 20px;
        padding-right: 20px;
    }

    .home-usps ul {
        margin-left: -20px;
    }

    .home-usps ul li {
        padding-left: 20px;
    }

    .slider-section .uk-slideshow::before {
        right: 0px;
    }

    .productslider-home, .home-categories {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .home-usps {
        margin-top: 60px;
        margin-bottom: 40px;
    }

    .home-usps .usp-container {
        min-width: 200px;
        min-height: 200px;
    }

    .home-usps .usp-container::before {
        min-width: 200px;
        min-height: 200px;
    }

    .home-new #overview-new-products > div.col {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .home-header-logo-mobile a {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
    }

    .header-home > .container-fluid-desktop {
        display: none;
    }

    .usps-home {
        position: relative;
        z-index: 0;
        padding-top: 40px;
        padding-bottom: 20px;
    }
    .usps-home-ul li {
        display: block;
        max-width: none !important;
        line-height: 55px;
    }
    .usps-home .col-md-5 > div img {
        position: absolute;
        top: calc(50% + 227px);
    }

    .product-usps-section hr {
        margin-top: 20px !important;
    }

    .home-1 {
        padding-top: 40px;
    }


    .home-block2 .col-md-7 {
        padding-top: 40px;
        padding-left: 15px;
    }

    .home-block2 .col-md-7 > div {
        padding-left: 0px;
    }

    .home-block2 .col-md-7 .quote {
        font-size: 26px;
        line-height: 36px;
    }

}


@media (min-width: 660px) and (max-width: 959px) {
    header .container-fluid-mobile>div>div.col-8 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (min-width: 960px) {
    .productslider-home, .home-categories, .home-usps {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .over-ons-cat {
        margin-top: 30px;
        margin-bottom: 30px;
    }

}







@media (max-width: 767px) {


    .slider-section > div {
        overflow: hidden;
    }

    .passie-voor-sieraden .col-md-5 * {
        max-width: none;
    }

    .passie-voor-sieraden > div > div > div.col-md-7 {
        padding-top: 20px;
        display: none;
    }

    .home-block2 > div > div > div.col-md-5 {
        display: none;
    }

    .home-categories {
        margin-top: 20px;
        margin-bottom: 0px;
    }

    .home-new #overview-new-products > div.col {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .home-1 > div > div > div:nth-child(2){
        padding-top: 40px;
    }

    .home-2 > div > div > div:nth-child(1){
        order: 2;-webkit-order:2;
        padding-top: 20px;
    }

    .home-2 > div > div > div:nth-child(2){
        order: 1;-webkit-order:1;
    }

    .usps-home .col-md-5 > div img {
        position: relative;
        top: 0px !important;
        left: 0px !important;
        -webkit-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -o-transform: none !important;
        transform: none !important;
        margin-bottom: -175px;
    }

    .productslider > div.container, #related-products > div.container,
    .productslider .uk-slider-container, #related-products .uk-slider-container {
        /*max-width: 80%;*/
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .productslider .uk-position-center-left-out {
        right: calc(100% - 60px);
    }
    .productslider .uk-position-center-right-out {
        left: calc(100% - 60px);
    }
    #related-products .uk-position-center-left-out {
        right: calc(100% - 60px);
    }
    #related-products .uk-position-center-right-out {
        left: calc(100% - 60px);
    }

    .counter {
        padding-top: 40px;
        padding-bottom: 20px;
    }

    .counter ul li {
        margin-bottom: 20px;
    }

    .productslider hr {
        margin-top: 20px;
    }

    .home-producten-all {
        padding-bottom: 20px;
    }

    .home-product-slider .uk-slider-items {
        /*flex-direction: column !important;
        -webkit-flex-direction: column !important;*/
    }

    .home-product-slider .uk-slider-items li {
        flex: 1 0 100% !important;
        max-width: 100% !important;
        width: 100% !important;
        display: block !important;
    }

}





@media (max-width: 500px) {

    #home-usps ul li {
        margin-bottom: 20px;
    }

    #home-usps ul li:last-child {
        margin-bottom: 0px;
    }

    .slider-section ul.uk-slideshow-items:after {
        top: 66%;
        left: auto;
    }




}


@media (max-width: 480px) {

    .home-new #overview-new-products > div.col {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .slider-text p {
        font-size: 20px;
    }

    .passie-voor-sieraden h2 {
        font-size: 20px;
    }

}
