$main-font1: 'Gothic A1';
$main-font2: 'Inter-Bold';
$main-font3: 'Inter-Regular';
$main-font4: 'Inter-Medium';
$main-font5: 'Inter-SemiBold';
$main-font6: 'Inter-Light';
$main-font7: 'Playfair-Display-Regular';
$main-font8: 'Poppins Medium';
$main-font9: 'Poppins Semi Bold';
$main-font10: 'Poppins Regular';

$main-font: aviano-sans, sans-serif;

$bold-font:  'Caladea', serif;
$h-font: 'Roboto', sans-serif;
$hand-font: 'Dream  Catcher Regular', serif;

.handwriting {
    //font-family: $hand-font;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
    content: "\e900";
    transform: translateY(2px);
    display: inline-block;
}
.icon-user:before {
    content: "\e901";
    transform: translateY(2px);
    display: inline-block;
}
.icon-shopping-cart:before {
    content: "\e902";
    transform: translateY(2px);
    display: inline-block;
}
.icon-heart:before {
    content: "\e903";
    transform: translateY(2px);
    display: inline-block;
}

h1, h2, h3, h4, h5, h6 {
    font: {
        //family: $h-font;
        weight:600;
    }
}

h1 {
    text-transform: uppercase;
}

h1, h2 {
    letter-spacing: -1px;
}

body .uk-h2, body h2 {
  /*  font-family: $main-font9;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -1.2px;*/
    font-family: $main-font7;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}






@media (max-width: 767px) {
    body .uk-h2, body h2 {
        font-size: 26px;
        line-height: 36px;
    }
}
