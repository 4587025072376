
header {
    position: sticky;
    top:0;
    z-index: 1010;
   /* background-color: $beige;*/
    background-color: #FFF;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;


    .top-icons img {
        height: 20px !important;
        object-fit: contain;
        cursor: pointer;
    }

    .top-icons svg {
        height: 20px !important;
        object-fit: contain;
        cursor: pointer;
    }

    @media (min-width: 960px) and (max-width: 1199px) {
        > div:last-child > div > div:nth-child(3) {
            padding-right: 45px;
        }
    }
}



.header-logo {
    width: 226px;
    object-fit: contain;
}

.header-desktop > div {
    max-width: 1200px;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-bottom: 30px !important;
}

.header-desktop > div > div {
    padding-bottom: 15px;
    border-bottom: 2px solid #D7C7B6;
}



.home-header-logo, .home-header-logo > div {
    padding-left: 0px;
}

.header-logo, .header-logo-mobile {
    position: relative;
    left: -7px;
}

.header-general {
    background: $white;
    box-shadow: none;
}

.header-general-top {
    border-bottom: 1px solid rgba(7, 41, 57, 0.10);
    background: rgba(7, 41, 57, 0.06);
}

.header-general-top > .container {
    max-width: 1230px;
}

.header-general-top h1 {
    margin-top: 26px;
    margin-bottom: 26px;
    font-family: $main-font9;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
    letter-spacing: -1.2px;
}

.header-general-top h1 .nav-back {
    display: inline-block;

}

.header-general-top h1 .nav-back a {
    color: $blue-light;
    border-radius: 60px;
    background-color: transparent;
    border: 1px solid $blue-light;
    padding: 10px 21px;
    font-family: $main-font7;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
/*    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;*/
    display: inline-block;
    letter-spacing: 0px;

    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border-radius: 60px;
        z-index: -2;
    }
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken($blue-light, 15%);
        transition: all .3s;
        border-radius: 60px;
        z-index: -1;
    }
    &:hover {
        color: #FFF;
        &:before {
            width: 100%;
        }
    }

}

.header-general-top h1 .nav-back a:hover,
.header-general-top h1 .nav-back a:focus {
    //background-color: $blue-light;
    color: $white;
    text-decoration: none;
}

.h1-product-title {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.mini-cart {
    position: relative;
    a, a.visited {
        color: $main-color;
    }
    .badge-main {
        background-color: $main-color;
        position: absolute;
        right: 7px;
        top: 14px;
        color: $beige;
        z-index: 999;
    }
}






.mobile-menu-block-open-bottom {
    padding-top: 20px;
}

.mobile-menu-block-open-bottom > * + * {
    margin-left: 20px;
}


.mobile-menu-block-open-bottom *:hover,
.mobile-menu-block-open-bottom *:focus {
    text-decoration: none;
}

.searchpage .searchpage-content .search-field-input {
    width: 80%;
    display: inline-block;
    float: left;
}

.searchpage .searchpage-content .header-search {
    max-width: none;
}


.searchpage .searchpage-content .search-field-input input {
    width: 100%;
    color: #000 !important;
    text-align: left;
    padding: 5px 15px;
    height: 44px;
}


.searchpage .searchpage-content .do-search-button {
    width: 20%;
    display: inline-block;
    float: left;
    height: 44px;
    background-color: $main-color;
    color: #FFF;
    line-height: 44px;
    cursor: pointer;
}


.searchpage .searchpage-content .do-search-button svg {
    float: right;
    position: relative;
    top: 12px;
    right: 15px;
}


.mobile-menu-block-icons {
    margin-top: 40px;
}

.mobile-menu-block-icons a:hover,
.mobile-menu-block-icons a:focus {
    text-decoration: none;
}


.mobile-menu-block-icons img {
    height: 20px !important;
    object-fit: contain;
    cursor: pointer;
}


.mobile-menu-block-icons .mini-cart .badge-main {
    right: -15px;
}

.mobile-menu-block-icons a:hover img,
.mobile-menu-block-icons a:focus img {
    //filter: invert(61%) sepia(81%) saturate(490%) hue-rotate(165deg) brightness(94%) contrast(98%);
}

.mobile-menu-block-icons .user-login-link {
    margin-right: 20px;
}

.mobile-menu-block-icons .mini-cart {
    margin-left: 20px;
}

.mobile-menu-block-logo {
    position: fixed;
    bottom: 20px;
    left: 15px;
    right: 15px;
    z-index: 999;
}

.mobile-menu-block-logo img {
    object-fit: contain;
}

.mobile-menu-block-logo .header-logo {
    display: none !important;
}



.slider-section .uk-dotnav>*>* {
    background: $white;
    border: 1px solid $white;
}

.slider-section .uk-dotnav>*>:hover,
.slider-section .uk-dotnav>*>:focus {
    background: $beige;
    border: 1px solid $beige;
}

.slider-section .uk-dotnav>.uk-active>* {
    background: $brown;
    border: 1px solid $brown;
}

.top-menu-label {
    font-family: $main-font3;
    color: $brown;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    padding-top: 12px;
    padding-right: 10px;
}
.top-menu-label span {
    font-family: $main-font2;
    color: $brown;
    font-size: 12px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
}


@media (min-width: 960px) {

    .mobile-menu-block, header .container-fluid-mobile {
        display: none;
    }

    header .top-menu-icons > * + * {
        margin-left: 20px;
    }

    .logincontainer {
        padding-top: 70px;
        padding-bottom: 70px;
    }

}







@media (max-width: 1199px) {
    .logincontainer > div > div.col-md-8 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-width: 959px) {

    .header-logo {
        display: none;
    }

    .header-logo-mobile {
        width: 226px;
        object-fit: contain;
    }

    .header-desktop > div > div {
        border-bottom: 0px;
        padding-bottom: 5px;
    }

    .logincontainer {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .mob-right .user-login-link {
        margin-right: 20px;
    }

    .menu-mobile-menu > li.has-sub > ul.sub-menu > li.has-sub > ul.sub-menu {
        display: none;
    }

    .menu-mobile-menu > li.has-sub > ul.sub-menu > li.has-sub > ul.sub-menu.open {
        display: block;
    }


    header .container-fluid-mobile .main-nav-container {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    /*header .container-fluid-mobile .main-nav-container,*/
    header .container-fluid-desktop {
        display: none;
    }

    header .container-fluid-mobile {
        padding-left: 20px !important;
        padding-right: 20px !important;
        padding-top: 5px !important;
        /*padding-bottom: 5px !important;*/
        padding-bottom: 0px !important;
    }

    header .container-fluid-mobile .header-mobile-col8 {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
    }

    .header-desktop .mobile-menu-block {
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        height: 100svh;
        width: 75vw;
        background: #FFF;
        pointer-events: none;
        transform: translateX(-100%);
        opacity: 0;
        transition: all 300ms ease;
    }

    .header-desktop .mobile-menu-block.open {
        z-index: 9999;
        opacity: 1;
        transform: translateX(0);
        pointer-events: all;
        //box-shadow: 5px 0 5px -5px #aaa;
        box-shadow: 5px 0 5px -5px #222;
    }

    .header-desktop .mobile-menu-block.open .close-menu {
        padding-right: 15px;
        padding-top: 15px;
        text-align: right;
        cursor: pointer;
    }

    .header-desktop .mobile-menu-block.open .close-menu svg {
        font-size: 24px;
        color: #1D1D1B;
    }

    .header-desktop .mobile-menu-block.open .close-menu:hover svg {
        font-size: 24px;
        color: $brown;
    }

    .header-desktop .mobile-menu-block.open .menu-mobile-menu {
        list-style: none;
        text-align: left;
    }

    .header-desktop .mobile-menu-block.open .menu-mobile-menu li.has-sub {
        margin-top: 10px;
    }

    .header-desktop .mobile-menu-block.open .menu-mobile-menu .sub-menu {
        list-style: none;
        margin-top: 10px;
    }

    .header-desktop .mobile-menu-block.open .menu-mobile-menu li + li {
        margin-top: 10px;
    }


    .header-desktop .mobile-menu-block.open .menu-mobile-menu > li.has-sub > ul.sub-menu > li.has-sub > ul.sub-menu {
        margin-top: 20px;
        margin-bottom: 20px;
        display: none !important;
    }

    .header-desktop .mobile-menu-block.open .menu-mobile-menu * {
        font-family: $main-font3;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: $main-color;
        text-underline-position: under;
    }

    .header-desktop .mobile-menu-block.open .menu-mobile-menu li.active a {
        font-family: $main-font2;
        font-weight: 700;
    }

    .header-desktop .mobile-menu-block.open .menu-mobile-menu > li.has-sub > a {
        font-size: 13px;
    }

    .header-desktop .mobile-menu-block.open .menu-mobile-menu li.has-sub ul.sub-menu a {
        font-size: 12px;
    }

    .header-desktop .mobile-menu-block.open .menu-mobile-menu *:hover,
    .header-desktop .mobile-menu-block.open .menu-mobile-menu *:focus {
        /*color: $pastel-3-accent;*/
        /*color: #FF4200;*/
        color: #8D533D;
    }

}





@media (max-width: 767px) {
    .searchpage .searchpage-content .search-field-input {
        width: 70%;
    }
    .searchpage .searchpage-content .do-search-button {
        width: 30%;
    }
    .logincontainer .butn-login {
        width: 100%;
        margin-bottom: 20px;
    }

    .logincontainer .btn-link {
        /*padding-left: 0;
        padding-right: 0;*/
        width: 100%;
    }
}


@media (min-width: 960px) and (max-width: 991px) {
    header .container-fluid-desktop > div > div.col-md-2 {
        flex: 0 0 20%;
        max-width: 20%;
    }
    header .container-fluid-desktop > div > div.col-md-8 {
        flex: 0 0 60%;
        max-width: 60%;
    }
}


@media (min-width: 660px) and (max-width: 959px) {
    header .top-menu-icons > * + * {
        margin-left: 15px;
    }
    header .container-fluid-mobile > div > div.col-2 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    header .container-fluid-mobile > div > div.col-8 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}




@media (max-width: 1440px) {

    header.header-desktop > div {
        padding-left: 30px !important;
        padding-right: 30px !important;
        max-width: 1230px;
    }

    .home-header-logo > div {
        padding-left: 0px;
    }

}


@media (min-width: 960px) and (max-width: 1199px) {
  /*  .header-home > div > div > div.col-md-6,
    .header-general > div > div > div.col-md-6 {
        flex: 0 0 40%;
        max-width: 40%;
    }

    .header-home > div > div > div.top-icons,
    .header-general > div > div > div.top-icons {
        !*flex: 0 0 35%;
        max-width: 35%;*!
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }*/

}


@media (max-width: 959px) {

    #contactButton2 {
        margin-right: 15px;
    }

    .mob-right {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
    }

    .mob-right .mini-cart {
        margin-right: 30px;
    }

    header.header-desktop > div {
        padding-left: 15px !important;
        padding-right: 15px !important;
        max-width: 1230px;
    }

}



@media (max-width: 680px) {
    #contactButton2 {
        display: none;
    }
}


@media (max-width: 659px) {
    header .top-menu-icons > *:not(:last-child) {
        margin-bottom: 20px;
    }

    header .top-menu-icons > div {
        display: block !important;
    }

    .mob-right .mini-cart {
        margin-right: 25px;
    }


    .container-fluid-mobile > div > div.col-8 {
        flex: 0 0 56.6666666667%;
        max-width: 56.6666666667%;
    }

    .container-fluid-mobile > div > div.col-4 {
        flex: 0 0 43.3333333333%;
        max-width: 43.3333333333%;
    }


    .top-icons a, .top-icons .search-field {
        margin-top: 5px;
        margin-right: 10px;
    }



}


@media (max-width: 480px) {
    .searchpage .searchpage-content .search-field-input {
        width: 100%;
    }
    .searchpage .searchpage-content .do-search-button {
        width: 100%;
    }

    .mob-right .user-login-link {
        margin-right: 15px;
        margin-top: 8px;
    }
    .mob-right .mini-cart {
        margin-right: 0px;
        margin-top: 8px;
    }

    .header-general-top h1 {
        font-size: 26px;
        line-height: 36px;
    }

}
