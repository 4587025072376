// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  color: $body-color;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  @include hover {
    color: $body-color;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    /*box-shadow: $btn-focus-box-shadow;*/
      box-shadow: none;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    /*@include box-shadow($btn-active-box-shadow);*/
    @include box-shadow(none);

    &:focus {
      /*@include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);*/
      @include box-shadow(none);
    }
  }

    border-radius: 0px !important;

}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}


//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}


.btn-primary {
    color: #fff;
    border-radius: 0px;
    background-color: #757151;
    border-color: #757151;
    padding: 12px 54px;
    font-family: 'Inter-Medium';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
/*    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;*/

    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:after {
        content: "";
        background: #8D533D;
        position: absolute;
        z-index: -1;
        padding: 0.85em 0.75em;
        display: block;
        transition: all 0.3s ease;
        left: -20%;
        right: -20%;
        top: 0;
        bottom: 0;
        transform: skewX(45deg) scale(0, 1);
    }
    &:before {
     /*   content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken(#072939, 15%);
        transition: all .3s;
        border-radius: 60px;
        z-index: -1;*/
    }
    &:hover {
        color: #FFF;
     /*   &:before {
            width: 100%;
        }*/
        &:after {
            transition: all 0.3s ease-out;
            transform: skewX(45deg) scale(1, 1);
        }
    }

}

.btn-primary:hover,
.btn-primary:focus {
    //background-color: #072939;
    background-color: #8D533D;
    border-color: #8D533D !important;
    //border-color: #072939 !important;
    box-shadow: none !important;
}

.btn-secondary {
    color: #8D533D;
    border-radius: 0px;
    background-color: #FFF;
    border-color: #FFF;
    padding: 12px 54px;
    font-family: 'Inter-Medium';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
/*    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;*/
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:after {
        content: "";
        background: #D7C7B6;
        position: absolute;
        z-index: -1;
        padding: 0.85em 0.75em;
        display: block;
        transition: all 0.3s ease;
        left: -20%;
        right: -20%;
        top: 0;
        bottom: 0;
        transform: skewX(-45deg) scale(0, 1);
    }
    &:before {
       /* content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken(#3FBDEE, 15%);
        transition: all .3s;
        border-radius: 60px;
        z-index: -1;*/
    }
    &:hover {
        color: #8D533D;
       /* &:before {
            width: 100%;
        }*/
        &:after {
            transition: all 0.3s ease-out;
            transform: skewX(-45deg) scale(1, 1);
        }
    }

}

.btn-secondary:hover,
.btn-secondary:focus {
    color: #8D533D;
    //background-color: #3FBDEE;
    background-color: #D7C7B6;
    border-color: #D7C7B6;
    box-shadow: none !important;
}

.btn-tertiary {
    color: #757151;
    border-radius: 0px;
    border: 1px solid #757151;
    background: transparent;
    padding: 11px 28px;
    font-family: 'Inter-Medium';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
/*    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;*/

    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:after {
        content: "";
        background: rgba(117,113,81,0.2);
        position: absolute;
        z-index: -1;
        padding: 0.85em 0.75em;
        display: block;
        transition: all 0.3s ease;
        left: -20%;
        right: -20%;
        top: 0;
        bottom: 0;
        transform: skewX(45deg) scale(0, 1);
    }

  /*  &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border-radius: 60px;
        z-index: -2;
    }
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken(#3FBDEE, 15%);
        transition: all .3s;
        border-radius: 60px;
        z-index: -1;
    }*/
    &:hover {
        color: #757151;
        /*&:before {
            width: 100%;
        }*/

        &:after {
            transition: all 0.3s ease-out;
            transform: skewX(45deg) scale(1, 1);
        }
    }

}

.btn-tertiary:hover,
.btn-tertiary:focus {
    //background: #3FBDEE;
    box-shadow: none !important;
}


//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  //color: $link-color;
    color: #072939;
  //text-decoration: $link-decoration;

  @include hover {
    //color: $link-hover-color;
      color: #072939;
    //text-decoration: $link-hover-decoration;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}


//
// Button Sizes
//

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}


//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}



.btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: none !important;
}
